var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-form", { ref: "form", attrs: { "label-width": "68px" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "wrap",
        attrs: { id: "wrap" },
      },
      [
        _c(
          "cardTitleCom",
          {
            staticStyle: { "margin-bottom": "0" },
            attrs: { cardTitle: "查询", id: "search-card" },
          },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "navSearch" },
                [
                  _c("seniorSearch", {
                    class: { marB10: !_vm.showHigh },
                    attrs: {
                      filterTimeStr: _vm.thisMonth,
                      getList: _vm.getList,
                    },
                    on: { isShowHighCom: _vm.getIsShowHigh },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showHigh,
                          expression: "showHigh",
                        },
                      ],
                      staticClass: "marT10 x-w",
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据编号", prop: "billNo" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth marR15",
                            attrs: { size: "mini", placeholder: "全部" },
                            model: {
                              value: _vm.queryParams.billNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "billNo", $$v)
                              },
                              expression: "queryParams.billNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据状态", prop: "billStatuss" } },
                        [
                          _c("SelectLocal", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "bill_status" })
                                .option,
                            },
                            model: {
                              value: _vm.queryParams.billStatuss,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "billStatuss", $$v)
                              },
                              expression: "queryParams.billStatuss",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "生产工厂", prop: "produceDeptIds" },
                        },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listProduceDept",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.produceDeptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "produceDeptIds", $$v)
                              },
                              expression: "queryParams.produceDeptIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产组", prop: "produceGroupIds" } },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listProduceGroup" })
                                .option,
                            },
                            model: {
                              value: _vm.queryParams.produceGroupIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "produceGroupIds",
                                  $$v
                                )
                              },
                              expression: "queryParams.produceGroupIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "生产班次", prop: "produceOrderIds" },
                        },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listProduceOrder" })
                                .option,
                            },
                            model: {
                              value: _vm.queryParams.produceOrderIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "produceOrderIds",
                                  $$v
                                )
                              },
                              expression: "queryParams.produceOrderIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
        _c(
          "cardTitleCom",
          {
            staticClass: "orderInfo",
            attrs: { cardTitle: "单据信息", hideCard: true },
          },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "tableContent" },
                [
                  _c("operatingButton", {
                    staticClass: "tableBtn",
                    attrs: {
                      getList: _vm.getList,
                      isAuditBtn: true,
                      isExportBtn: true,
                      multiple: _vm.multiple,
                    },
                    on: {
                      handleAdd: _vm.handleAdd,
                      handleDelete: function ($event) {
                        return _vm.auditBill("删除")
                      },
                      handleAudit: function ($event) {
                        return _vm.auditBill("审核")
                      },
                      handleRedoAudit: function ($event) {
                        return _vm.auditBill("反审核")
                      },
                      handleExport: _vm.handleExport,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "specialDiyBtn",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "x-f marR10",
                                staticStyle: { "align-items": "center" },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "guide",
                                    attrs: {
                                      icon: "iconfont icon-qizi",
                                      size: "mini",
                                    },
                                    on: { click: _vm.handleGuide },
                                  },
                                  [_vm._v("生成向导 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "pickMaterial",
                                    class: {
                                      opacityDisabled: _vm.singleMaterial,
                                    },
                                    attrs: {
                                      icon: "iconfont icon-shengchanlingliao",
                                      size: "mini",
                                      disabled: _vm.singleMaterial,
                                    },
                                    on: { click: _vm.getLeadMaterial },
                                  },
                                  [_vm._v("领料 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "putStorage",
                                    class: { opacityDisabled: _vm.singleStore },
                                    attrs: {
                                      icon: "iconfont icon-ruku1",
                                      size: "mini",
                                      disabled: _vm.singleStore,
                                    },
                                    on: { click: _vm.getInStoreDia },
                                  },
                                  [_vm._v("入库 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "finish",
                                    class: { opacityDisabled: _vm.multiple },
                                    attrs: {
                                      icon: "iconfont icon-wangongguanlibeifen2x",
                                      size: "mini",
                                      disabled: _vm.multiple,
                                    },
                                    on: { click: _vm.getFinish },
                                  },
                                  [_vm._v("完工 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "distributeGoods",
                                    class: {
                                      opacityDisabled: _vm.singleSubdivision,
                                    },
                                    attrs: {
                                      icon: "iconfont icon-assignPermissions-o",
                                      size: "mini",
                                      disabled: _vm.singleSubdivision,
                                    },
                                    on: { click: _vm.getStoreDistribute },
                                  },
                                  [_vm._v("分货 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      disabled: _vm.ids.length <= 0,
                                    },
                                    on: { click: _vm.getRelevantDocuments },
                                  },
                                  [_vm._v("相关单据 ")]
                                ),
                                _c("PrintButton", {
                                  staticStyle: { "margin-left": "8px" },
                                  attrs: {
                                    printTemplate: {
                                      print: {},
                                      label: "打印",
                                      type: "primary",
                                      btnType: "dropdown",
                                    },
                                    check: _vm.selectionList,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableData,
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                      },
                      on: {
                        "row-click": _vm.handleRowClick,
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          prop: "userId",
                          width: "80",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "单据编号",
                          align: "center",
                          prop: "billNo",
                          "min-width": "160",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      target: "_blank",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetail(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(scope.row.billNo) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "手工单据号",
                          align: "center",
                          prop: "handBillNo",
                          "min-width": "160",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据状态",
                          align: "center",
                          prop: "billStatusName",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据日期",
                          align: "center",
                          prop: "billDate",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产工厂",
                          align: "center",
                          prop: "produceDeptName",
                          width: "130",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产组",
                          align: "center",
                          prop: "produceGroupName",
                          width: "130",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产班次",
                          align: "center",
                          prop: "produceOrderName",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "领料状态",
                          align: "center",
                          prop: "leadMaterialStatusName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "入库状态",
                          align: "center",
                          prop: "inStoreStatusName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "完工状态",
                          align: "center",
                          prop: "finishWorkStatusName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "分货状态",
                          align: "center",
                          prop: "planDistributeStatusName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产日期",
                          align: "center",
                          prop: "produceDate",
                          width: "170",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "备注",
                          align: "center",
                          prop: "billRemark",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建人",
                          align: "center",
                          prop: "createBy",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          align: "center",
                          prop: "createTime",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核人",
                          align: "center",
                          prop: "auditBy",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核时间",
                          align: "center",
                          prop: "auditTime",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "修改人",
                          align: "center",
                          prop: "updateBy",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "修改时间",
                          align: "center",
                          prop: "updateTime",
                          width: "155",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      idsCom: _vm.ids,
                      totalCom: _vm.total,
                    },
                    on: { selectAllCom: _vm.selectAll },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
        _c("c-dialog", {
          attrs: {
            title: "产品入库",
            width: 1210,
            showDialog: _vm.openInStoreGoodsList,
          },
          on: {
            "update:showDialog": function ($event) {
              _vm.openInStoreGoodsList = $event
            },
            "update:show-dialog": function ($event) {
              _vm.openInStoreGoodsList = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "x-c-end" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "marL10 marB10",
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.getDistribution },
                        },
                        [_vm._v("批量修改仓库")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    { ref: "formStore" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingInStoreDia,
                              expression: "loadingInStoreDia",
                            },
                          ],
                          ref: "multipleTableInStore",
                          attrs: {
                            "tooltip-effect": "dark",
                            data: _vm.inStoreGoodsList,
                            border: "",
                            "max-height": "500",
                            height: "500",
                          },
                          on: {
                            "selection-change":
                              _vm.handleSelectionChangeInStore,
                            "row-click": _vm.handleRowClickInStore,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              width: "60",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              align: "center",
                              prop: "userId",
                              width: "80",
                              type: "index",
                              fixed: "left",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": "",
                              label: "商品编码",
                              align: "center",
                              prop: "goodsNo",
                              "min-width": "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "商品名称",
                              align: "center",
                              prop: "goodsName",
                              "min-width": "155",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              align: "center",
                              prop: "goodsSpec",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "单位",
                              align: "center",
                              prop: "unitName",
                              "min-width": "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "生产计划数",
                              align: "center",
                              prop: "unitQty",
                              "min-width": "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "本次入库数",
                              align: "center",
                              prop: "nowInUnitQty",
                              "min-width": "130",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { size: "mini" },
                                      model: {
                                        value: scope.row.nowInUnitQty,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "nowInUnitQty",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.nowInUnitQty",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "入库仓库",
                              align: "center",
                              prop: "inStoreId",
                              "min-width": "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("SelectRemote", {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        option: _vm.$select({
                                          key: "listProduceStore",
                                          option: {
                                            option: {
                                              remoteBody: {
                                                produceDeptId:
                                                  _vm.planForm.produceDeptId,
                                              },
                                              showItem: [
                                                {
                                                  storeId: scope.row.inStoreId,
                                                  produceStoreName:
                                                    scope.row.inStoreName,
                                                },
                                              ],
                                            },
                                          },
                                        }).option,
                                      },
                                      model: {
                                        value: scope.row.inStoreId,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "inStoreId", $$v)
                                        },
                                        expression: "scope.row.inStoreId",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "已入库数",
                              align: "center",
                              prop: "inUnitQty",
                              "min-width": "100",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "buttonList",
              fn: function () {
                return [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getInStore },
                    },
                    [_vm._v("确 定")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Dialog", {
          ref: "tablePage",
          attrs: { options: _vm.dialogOptions },
          on: {
            "update:options": function ($event) {
              _vm.dialogOptions = $event
            },
            handleEvent: _vm.handleEvent,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }