<template>
  <el-form ref="form" label-width="68px">
    <div class="wrap" id="wrap" v-loading="loading">
      <!-- 顶部按钮 -->
      <topOperatingButton :isSubmitAddBtn="false" :isAuditBillBtn="false" @submitForm="submitForm" @getQuit="getQuit"
        id="topOperatingButton" />
      <cardTitleCom cardTitle="查询" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <div class="marB10">
              <span class="fS14MR10">单据类型</span>
              <el-checkbox v-model="queryParams.isShopOrder" class="checkOrder">门店订货单</el-checkbox>
              <el-checkbox v-model="queryParams.isSell" class="checkOrder">销售订单</el-checkbox>
              <el-checkbox v-model="queryParams.isProduce" class="checkOrder">生产计划单
              </el-checkbox>
              <el-checkbox v-model="queryParams.isProduceOtherOrder">其他订货单
              </el-checkbox>
              <!-- <el-checkbox v-model="queryParams.isShopDue" class="checkOrder"
                >门店预订单</el-checkbox
              > -->
            </div>
            <!-- 高级搜索按钮 -->
            <seniorSearch filterTimeStr="thisMonth" v-model="queryParams" :getList="getList" :isInvokeList="false"
              :refreshSearch="refreshQueryParams" @isShowHighCom="getIsShowHigh" :class="{ marB10: !showHigh }" />
            <!-- 查询条件 -->
            <div v-show="showHigh">
              <!-- 第一行 -->
              <div class="x-w marT10">
                <el-form-item label="到货日期" prop="datetimeArrival" class="marR10">
                  <el-date-picker size="mini" v-model="datetimeArrival" type="datetimerange" start-placeholder="开始日期"
                    end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="生产班次" prop="produceOrderIds" class="marR10">
                  <SelectRemote :multiple="true" v-model="queryParams.produceOrderIds" style="width: 250px" :option="
                      $select({
                        key: 'listProduceOrder',
                        option: {
                          option: {
                            remoteBody: {
                              produceDeptId: queryParams.produceDeptId,
                            },
                            multiple: true,
                            remoteEcho: 'produceOrderIds',
                          },
                        },
                      }).option
                    " />
                </el-form-item>
                <!-- <el-form-item label="取货门店" prop="pickupShopIds">
                  <SelectRemote
                    :multiple="true"
                    v-model="queryParams.pickupShopIds"
                    style="width: 250px"
                    :option="$select({ key: 'listShop' }).option"
                  />
                </el-form-item> -->
                <el-form-item label="订货仓库" prop="orderingWarehouseIds" class="marR10">
                  <SelectRemote :multiple="true" v-model="queryParams.orderingWarehouseIds" style="width: 250px"
                    :option="$select({ key: 'listStore' }).option" />
                </el-form-item>
                <el-form-item label="订货客户" prop="orderingCustomerIds">
                  <SelectRemote :multiple="true" v-model="queryParams.orderingCustomerIds" style="width: 250px"
                    :option="$select({ key: 'listCustomer' }).option" />
                </el-form-item>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="选择源单" class="orderInfo">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="x-bc">
              <div style="width:68%">
                <div class="fontS14 fontBold marB5">源单信息</div>
                <el-table style="width:100%" v-loading="loadingTable" ref="multipleTable" @row-click="handleRowClick"
                  tooltip-effect="dark" :data="sourceList" @selection-change="handleSelectionChange" border
                  :max-height="tableHeight" :height="tableHeight">
                  <el-table-column type="selection" width="50" align="center" fixed="left" />
                  <el-table-column label="序号" align="center" prop="userId" width="60" type="index" />
                  <el-table-column show-overflow-tooltip label="单据编号" align="center" prop="billNo" min-width="170" />
                  <el-table-column label="单据日期" align="center" prop="billDate" min-width="120" />
                  <el-table-column label="源单类型" align="center" prop="importTypeName" min-width="120" />
                  <el-table-column label="单据金额" align="center" prop="billMoney" min-width="120" />
                  <el-table-column label="到货日期" align="center" prop="arrivalDate" min-width="120" />
                  <el-table-column label="订单需求量" align="center" prop="billQty" min-width="120" />
                  <el-table-column label="订货仓库" align="center" prop="inStoreName" min-width="120" />
                  <el-table-column label="订货客户" align="center" prop="customerName" min-width="120" />
                  <el-table-column label="单据摘要" align="center" prop="billSummary" min-width="155" />
                  <el-table-column label="备注" align="center" prop="billRemark" min-width="160" />
                </el-table>
              </div>
              <div style="width:30%">
                <div class="fontS14 fontBold marB5">未订货门店</div>
                <el-table style="width:100%" v-loading="loadingTable" ref="multipleTable1" tooltip-effect="dark"
                  :data="sourceShopList" border :max-height="tableHeight" :height="tableHeight">
                  <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
                  <el-table-column label="分店" align="center" prop="shopNoName" min-width="120" />
                  <el-table-column label="负责人" align="center" prop="linkEmployeeName" min-width="120" />
                  <el-table-column label="电话" align="center" prop="linkEmployeeTel" min-width="120" />
                </el-table>
              </div>
            </div>
            <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
              @selectAllCom="selectAll" />
          </div>
        </template>
      </cardTitleCom>
    </div>
  </el-form>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import bottomPage from "@/views/components/bottomPage"; //底部分页
import {
  planGetWizardAPI,
  planGetWizardDetailAPI,
  planGetGoodsInfo,
} from "@/api/produce/bill/plan"; //生产计划单
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import { getTimes, getTimesIsPicker } from "@/utils/index.js";
export default {
  name: "planImport",
  dicts: ["bill_status"],
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    topOperatingButton,
    SelectRemote,
  },
  data() {
    return {
      datetimeArrival: undefined, //到货查询时间
      loading: false,
      loadingTable: false,
      single: true, // 非单个禁用
      selectionWizard: {}, //第二步选中的源单
      showHigh: false, //是否显示高级搜索
      ids: [], // 选中数组
      total: 0, // 总条数
      refreshQueryParams: {
        pageNum: 1,
        pageSize: 15,
        isShopOrder: true, //门店订货单    是否
        isSell: false, //销售订单    是否
        isProduce: false, //生产计划单    是否
        isShopDue: false, //门店预订单    是否
        isProduceOtherOrder: false, //生产其他订单    是否
        isBillDate: true, //单据日期    是否
        isArrivalDate: true, //到货日期   是否
        isOrderingWarehouse: false, //订货仓库   是否
        isOrderingCustomer: false, //订货客户  是否
        isPickupShop: false, //取货门店  是否
        isProduceGroup: false, //生产组  是否
        isProduceOrder: false, //生产班次  是否
        produceOrderIds: null,
        produceGroupId: null,
        produceDeptId: null,
      },
      // 表单参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        isShopOrder: true, //门店订货单    是否
        isSell: false, //销售订单    是否
        isProduce: false, //生产计划单    是否
        isShopDue: false, //门店预订单    是否
        isProduceOtherOrder: false, //生产其他订单    是否
        isBillDate: true, //单据日期    是否
        isArrivalDate: true, //到货日期   是否
        isOrderingWarehouse: false, //订货仓库   是否
        isOrderingCustomer: false, //订货客户  是否
        isPickupShop: false, //取货门店  是否
        isProduceGroup: false, //生产组  是否
        isProduceOrder: false, //生产班次  是否
        produceOrderIds: null,
        produceGroupId: null,
        produceDeptId: null,
      },
      sourceList: [], //源单信息
      sourceShopList: [], //源单门店信息
      sourceGoodsList: [], //源单商品信息
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      topOperatingH: 0, //顶部按钮高度
      paginationH: 40,
    };
  },
  watch: {
    //到货日期
    datetimeArrival(newVal) {
      if (newVal) {
        this.queryParams.beginArrivalDate = newVal[0];
        this.queryParams.endArrivalDate = newVal[1];
      } else {
        this.queryParams.beginArrivalDate = undefined;
        this.queryParams.endArrivalDate = undefined;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // 访问组件实例 vm 中的数据和方法
      if (
        vm.$route.query.type === "planImport" &&
        vm.$route.query.produceDeptId
      ) {
        vm.refreshQueryParams.produceDeptId = vm.$route.query.produceDeptId;
        vm.queryParams.produceDeptId = vm.refreshQueryParams.produceDeptId;
        // console.log('produceDeptId', vm.queryParams.produceDeptId)
        if (vm.$route.query.produceOrderId) {
          vm.refreshQueryParams.produceOrderIds = [
            vm.$route.query.produceOrderId,
          ].filter((ite) => typeof ite !== "undefined");
          vm.queryParams.produceOrderIds =
            vm.refreshQueryParams.produceOrderIds;
          vm.refreshQueryParams.isProduceOrder = true
          vm.queryParams.isProduceOrder = true
        }
        if (vm.$route.query.produceGroupId) {
          vm.$set(
            vm.refreshQueryParams,
            "produceGroupId",
            vm.$route.query.produceGroupId
          );
          vm.$set(
            vm.queryParams,
            "produceGroupId",
            vm.$route.query.produceGroupId
          );

          // console.log('query', vm.queryParams)
        }
        await vm.getList();
      }
    });
  },
  computed: {
    tableHeight() {
      return (
        this.vivwH - this.topOperatingH - this.searchH - this.paginationH - 62 - 20
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.topOperatingH =
        document.getElementById("topOperatingButton")?.clientHeight;
      this.searchH = document.getElementById("search-card")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.topOperatingH =
        document.getElementById("topOperatingButton")?.clientHeight;
      this.searchH = document.getElementById("search-card")?.clientHeight;
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      console.log("query2", this.refreshQueryParams);

      this.showHigh = value;
      this.$nextTick(() => {
        this.handleResize();
      });
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true;
      const { rows, total, shops } = await planGetWizardAPI(this.queryParams);
      this.sourceList = rows;
      this.sourceShopList = shops;
      this.total = total;
      this.loadingTable = false;
    },
    //确定
    async submitForm() {
      try {
        this.loading = true;
        if (this.selectionWizard?.queryList?.length > 0) {
          //获取第三步表格数据
          let res = await planGetWizardDetailAPI(this.selectionWizard);
          let data = res?.data || [];
          //查询库存条件
          let goodsIds = data.map((v) => v.goodsId);
          //查询库存
          let res2 = await planGetGoodsInfo({ goodsIds });
          let inventoryArr = res2.data || [];
          data = data.map((item) => {
            item.remark = undefined;
            //序号
            item.hoverRow = false;
            //手动添加
            item.lineType = 1;
            const storeNum = inventoryArr.find(
              (v) => v.goodsId == item.goodsId
            );
            //批产量
            item.batchProdQty = storeNum?.batchProdQty || 0;
            //半成品库存
            item.halfProductStoreQty = storeNum?.halfProductStoreQty || 0;
            //成品库存
            item.productStoreQty = storeNum?.productStoreQty || 0;
            //默认的生产计划数
            item.unitQty = item.needUnitQty;
            item.needUnitQty = item.needUnitQty || 0;
            item.batchProdQty = item.batchProdQty || 0;
            item.batchUnitQty =
              item.needUnitQty && item.batchProdQty
                ? (item.needUnitQty / item.batchProdQty).toFixed(1)
                : 0;
            return item;
          });

          this.sourceGoodsList = data;
          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              (item) => item.path === this.$route.path
            ),
            1
          );
          this.$destroy();
          this.$router.push({
            path: "/produce/bill/plan/planAdd",
            query: {
              sourceGoodsList: JSON.stringify(this.sourceGoodsList),
              queryList: JSON.stringify(this.selectionWizard.queryList),
              produceDeptId: this.queryParams.produceDeptId,
              produceGroupId: this.queryParams.produceGroupId,
              produceOrderId:
                this.queryParams.produceOrderIds?.length == 1
                  ? this.queryParams.produceOrderIds[0]
                  : "",
              type: "importPlan",
            },
          });
        } else {
          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              (item) => item.path === this.$route.path
            ),
            1
          );
          this.$destroy();
          this.$router.go(-1);
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    //取消
    getQuit() {
      // 关闭当前tab页签，打开新页签
      // this.$tab.closeOpenPage({
      //   path: '/produce/bill/plan/planAdd'
      // })
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      this.$destroy();
      this.$router.go(-1);
    },
    // 源单信息 多选框选中数据
    handleSelectionChange(selection) {
      let queryList = selection.map((item) => {
        let obj = {
          importBillId: item.importBillId,
          importType: item.importType,
        };
        return obj;
      });
      this.selectionWizard = {
        produceGroupIds: this.queryParams.produceGroupId
          ? [this.queryParams.produceGroupId]
          : null,
        queryList,
      };
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true;
        //获取全部采购订单数据
        const res = await planGetWizardAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total,
        });
        //重新渲染表格
        this.sourceList = res.rows;
        //表格多选
        this.sourceList.map((row) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value);
          });
        });
        this.loadingTable = false;
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 0px 20px;
    //筛选单据
    .checkOrder {
      margin-right: 50px;
    }
  }
  //源单信息表格
  .tableContent {
    margin: 6px 10px 10px 10px;
  }
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
</style>
