<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  listInferior,
  processInferiorToStoreLoss,
  processInferiorToStoreAllot
} from "@/api/produce/bill/scrapDeal"; //产品次品
export default {
  name: 'scrapDeal',
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '生产次品管理',
        listNo: true, // 序号
        rowKey: 'billDetailId',
        getListApi: listInferior,
        //搜索
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '商品编码/商品名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'goodsNos', label: '商品编码' },
              { filter: 'goodsNames', label: '商品名称' },
            ]
          },
          this.$select({
            key: 'listGoods',
            option: {
              filter: 'goodsIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'goods' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'sales',
        buttons: [
          {
            click: 'reportDamage',
            label: '仓库报损',
            type: 'primary'
          },
          {
            click: 'transfer',
            label: '仓库调拨单',
            type: 'primary',
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        tabsColumns: [
          {
            title: '生产次品',
            type: 'sales',
            defaultBody: { inferiorType: 1 },
            //表格
            columns: [
              {
                prop: "billNo",
                label: "单据号",
                minWidth: 180,
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 180,
              },
              {
                prop: "billTypeName",
                label: "单据类型",
                formatter: (v, row) => row.billTypeName,
                minWidth: 120,
              },
              {
                prop: "billStatusName",
                label: "单据状态",
                formatter: (v, row) => row.billStatusName,
                minWidth: 120,
              },
              {
                prop: "produceInferiorProcessInfo",
                label: "处理结果单",
                type: "link",
                click: "routerLinkToAdjust",
                formatter: (v, row) => row?.produceInferiorProcessInfo?.importBillNo,
                minWidth: 160,
              },
              {
                prop: "produceInferiorProcessInfo",
                label: "处理单据类型",
                formatter: (v, row) => row?.produceInferiorProcessInfo?.importBillTypeName,
                minWidth: 160,
              },
              {
                prop: "produceInferiorProcessResult",
                label: "处理结果",
                minWidth: 140,
              },
              {
                prop: "importStatusName",
                label: "处理状态",
                formatter: (v, row) => row?.produceInferiorProcessInfo?.importStatusName,
                minWidth: 160,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                formatter: (v, row) => row.goodsInfo?.goodsNo,
                minWidth: 120,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                formatter: (v, row) => row.goodsInfo?.goodsName,
                minWidth: 120,
              },
              {
                prop: "barcode",
                label: "商品条码",
                formatter: (v, row) => row.goodsInfo?.barcode,
                minWidth: 120,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                formatter: (v, row) => row.goodsInfo?.goodsSpec,
                minWidth: 120,
              },
              {
                prop: "unitName",
                label: "单位",
                formatter: (v, row) => row.goodsInfo?.unitName,
                minWidth: 120,
              },
              {
                prop: "unitQty",
                label: "入库数量",
                minWidth: 120,
              },
              {
                prop: "unitEligibleQty",
                label: "合格数",
                minWidth: 120,
              },
              {
                prop: "unitProduceInferiorQty",
                label: "生产次品数",
                minWidth: 120,
              }
            ],
            summary: ['unitQty', 'unitMoney', 'lineGain']
          },
          {
            title: '包装产品',
            type: 'salesMoney',
            defaultBody: { inferiorType: 2 },
            //表格
            columns: [
              {
                prop: "billNo",
                label: "单据号",
                minWidth: 180,
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 180,
              },
              {
                prop: "billTypeName",
                label: "单据类型",
                formatter: (v, row) => row.billTypeName,
                minWidth: 120,
              },
              {
                prop: "billStatusName",
                label: "单据状态",
                formatter: (v, row) => row.billStatusName,
                minWidth: 120,
              },
              {
                prop: "packInferiorProcessInfo",
                label: "处理结果单",
                type: "link",
                click: "routerLinkToAdjust",
                formatter: (v, row) => row?.packInferiorProcessInfo?.importBillNo,
                minWidth: 160,
              },
              {
                prop: "packInferiorProcessInfo",
                label: "处理单据类型",
                formatter: (v, row) => row?.packInferiorProcessInfo?.importBillTypeName,
                minWidth: 160,
              },
              {
                prop: "packInferiorProcessResult",
                label: "处理结果",
                minWidth: 140,
              },
              {
                prop: "importStatusName",
                label: "处理状态",
                formatter: (v, row) => row?.packInferiorProcessInfo?.importStatusName,
                minWidth: 160,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                formatter: (v, row) => row.goodsInfo?.goodsNo,
                minWidth: 120,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                formatter: (v, row) => row.goodsInfo?.goodsName,
                minWidth: 120,
              },
              {
                prop: "barcode",
                label: "商品条码",
                formatter: (v, row) => row.goodsInfo?.barcode,
                minWidth: 120,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                formatter: (v, row) => row.goodsInfo?.goodsSpec,
                minWidth: 120,
              },
              {
                prop: "unitName",
                label: "单位",
                formatter: (v, row) => row.goodsInfo?.unitName,
                minWidth: 120,
              },
              {
                prop: "unitQty",
                label: "入库数量",
                minWidth: 120,
              },
              {
                prop: "unitEligibleQty",
                label: "合格数",
                minWidth: 120,
              },
              {
                prop: "unitPackInferiorQty",
                label: "包装次品数",
                minWidth: 120,
              }
            ],
            summary: ['unitQty', 'unitMoney', 'lineGain']
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      let res = {}
      let checkArr = []
      let inferiorType = this.options.tabColumnType == 'sales' ? 1 : 2
      switch (type) {
        case 'reportDamage':
          checkArr = this.options.check.map(v => {
            return {
              billDetailId: v.billDetailId,
              inferiorType
            }
          }).filter(v => v != undefined)
          if (checkArr.length == 0) return this.$message.error('请选择要处理的数据')
          res = await processInferiorToStoreLoss(checkArr)
          res.data.detail = res.data.details.map(v => {
            v.storeId = v.outStoreId
            return v
          })
          this.$router.push({
            name: "storeWasteDetail",
            query: {
              type: "Add",
              orderInfo: JSON.stringify(res.data),
            },
          });
          console.log('res', res);
          break
        case 'transfer':
          checkArr = this.options.check.map(v => {
            return {
              billDetailId: v.billDetailId,
              inferiorType
            }
          }).filter(v => v != undefined)
          if (checkArr.length == 0) return this.$message.error('请选择要处理的数据')
          res = await processInferiorToStoreAllot(checkArr)
          this.$router.push({
            name: 'storeMoveDetail',
            query: {
              type: 'Add',
              orderInfo: JSON.stringify(res.data),
            }
          })
          console.log('res', res);
          break
        case 'routerLinkToAdjust':
          let type = this.options.tabColumnType == 'sales' ? row.produceInferiorProcessInfo.importBillType : row.packInferiorProcessInfo.importBillType
          let billId = this.options.tabColumnType == 'sales' ? row.produceInferiorProcessInfo.importBillId : row.packInferiorProcessInfo.importBillId
          if (type == '130103') {
            this.$router.push({
              name: "storeWasteDetail",
              query: {
                billId,
                type: "Update",
              },
            });
          } else if (type == '130104') {
            this.$router.push({
              name: 'storeMoveDetail',
              query: {
                billId,
                type: 'Update'
              }
            })
          }
          break
        default:
          break
      }
    }
  }
}
</script>
