<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <!-- 操作 -->
    <div class="navBtnTop x-c-end" id="search-card">
      <el-button size="mini" @click="lastStep" v-show="activeStep !== 0">上一步</el-button>
      <el-button size="mini" @click="nextStep" v-show="activeStep !== 3">下一步</el-button>
      <el-button type="primary" size="mini" v-show="activeStep === 3" @click="submitForm">保存</el-button>
      <el-button size="mini" @click="getQuit">退出</el-button>
    </div>
    <div class="content">
      <cardTitleCom cardTitle="当前进度" id="current-progress">
        <template slot="cardContent">
          <div class="theSteps">
            <el-steps text-align: left :active="activeStep" finish-status="success">
              <el-step title="选择生成计划单条件"></el-step>
              <el-step title="引入源单"></el-step>
              <el-step title="生成生产计划单"></el-step>
            </el-steps>
          </div>
        </template>
      </cardTitleCom>
      <!-- 配方原料替换 -->
      <cardTitleCom cardTitle="基本信息" v-show="activeStep == 0" :style="{height:tableHeight+'px'}">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="filterCriteria">
              <el-checkbox v-model="queryParams.isShopOrder">门店订货单</el-checkbox>
              <el-checkbox v-model="queryParams.isSell">销售订单</el-checkbox>
              <el-checkbox v-model="queryParams.isProduce">生产计划单</el-checkbox>
              <el-checkbox v-model="queryParams.isProduceOtherOrder">其他订货单</el-checkbox>
              <!-- <el-checkbox v-model="queryParams.isShopDue"
                >门店预订单</el-checkbox
              > -->
            </div>
            <div>
              <!-- 单据日期 -->
              <div class="marT20">
                <el-checkbox v-model="queryParams.isBillDate" />
                <span class="fS14MR10 marL10">单据日期</span>
                <el-date-picker v-model="datetime" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" class="marR10">
                </el-date-picker>
                <el-radio-group v-model="filterTime" size="medium">
                  <el-radio-button label="today">今天</el-radio-button>
                  <el-radio-button label="yesterday">昨天</el-radio-button>
                  <el-radio-button label="thisWeek">本周</el-radio-button>
                </el-radio-group>
              </div>
              <!-- 到货日期 -->
              <div class="marT20">
                <el-checkbox v-model="queryParams.isArrivalDate" />
                <span class="fS14MR10 marL10">到货日期</span>
                <el-date-picker v-model="datetimeArrival" type="datetimerange" start-placeholder="开始日期"
                  end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"
                  class="marR10">
                </el-date-picker>
                <el-radio-group v-model="filterTimeArrival" size="medium">
                  <el-radio-button label="today">今天</el-radio-button>
                  <el-radio-button label="yesterday">昨天</el-radio-button>
                  <el-radio-button label="thisWeek">本周</el-radio-button>
                </el-radio-group>
              </div>
              <!-- 生产工厂 -->
              <div class="marT20">
                <i style="color: #ff4949; margin-left: 23px">* </i>
                <span class="fS14MR10">生产工厂</span>
                <SelectRemote @selectChange="changeProduceGroupChange" v-model="queryParams.produceDeptId"
                  style="width: 250px" :option="
                    $select({
                      key: 'listProduceDept',
                    }).option
                  " />
              </div>
              <!-- 订货仓库 -->
              <div class="marT20">
                <el-checkbox v-model="queryParams.isOrderingWarehouse" />
                <span class="fS14MR10 marL10">订货仓库</span>
                <SelectRemote :multiple="true" v-model="queryParams.orderingWarehouseIds" style="width: 250px"
                  :option="$select({ key: 'listStore' }).option" />
              </div>
              <!-- 订货客户 -->
              <div class="marT20">
                <el-checkbox v-model="queryParams.isOrderingCustomer" />
                <span class="fS14MR10 marL10">订货客户</span>
                <SelectRemote :multiple="true" v-model="queryParams.orderingCustomerIds" style="width: 250px"
                  :option="$select({ key: 'listCustomer' }).option" />
              </div>
              <!-- 取货门店 -->
              <div class="marT20">
                <el-checkbox v-model="queryParams.isPickupShop" />
                <span class="fS14MR10 marL10">取货门店</span>
                <SelectRemote :multiple="true" v-model="queryParams.pickupShopIds" style="width: 250px"
                  :option="$select({ key: 'listShop' }).option" />
              </div>
              <!-- 生产组 -->
              <div class="marT20" v-if="isPlanGroup == 'Y'">
                <el-checkbox v-model="queryParams.isProduceGroup" />
                <span class="fontS14c3 marL20 marR15">生产组</span>
                <SelectRemote v-model="queryParams.produceGroupId" style="width: 250px" :option="listProduceGroup" />
              </div>
              <!-- 生产班次 -->
              <div class="marT20 marB20">
                <el-checkbox v-model="queryParams.isProduceOrder" />
                <span class="fS14MR10 marL10">生产班次</span>
                <SelectRemote :multiple="true" v-model="queryParams.produceOrderIds" style="width: 250px"
                  :option="listProduceOrder" />
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>

      <!-- 选择源单 -->
      <cardTitleCom cardTitle="选择源单" v-show="activeStep == 1" :style="{height:tableHeight+'px'}">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="x-bc">
              <div style="width:68%">
                <div class="fontS14 fontBold marB5">源单信息</div>
                <el-table style="width:100%" ref="multipleTable" @row-click="handleRowClick" tooltip-effect="dark"
                  :data="sourceList" border :max-height="tableHeight2" :height="tableHeight2" :reserve-selection="true"
                  @selection-change="handleSelectionChange" v-loading="loadingSourceList" row-key="billId">
                  <el-table-column type="selection" width="50" align="center" />
                  <el-table-column label="序号" align="center" prop="userId" width="60" type="index" />
                  <el-table-column label="单据编号" align="center" prop="billNo" min-width="170" />
                  <el-table-column label="单据日期" align="center" prop="billDate" min-width="120" />
                  <el-table-column label="生产工厂" align="center" prop="deptName" min-width="120" />
                  <el-table-column label="生产组" align="center" prop="produceGroupName" min-width="120" />
                  <el-table-column label="生产班次" align="center" prop="produceOrderName" min-width="120" />
                  <el-table-column label="金额" align="center" prop="billMoney" min-width="100" />
                  <el-table-column label="订货仓库" align="center" prop="inStoreName" min-width="120" />
                  <el-table-column label="取货仓库" align="center" prop="outStoreName" min-width="120" />
                  <el-table-column label="到货日期" align="center" prop="arrivalDate" min-width="120" />
                  <el-table-column label="客户编码" align="center" prop="customerNo" min-width="120" />
                  <el-table-column label="客户名称" align="center" prop="customerName" min-width="120" />
                  <el-table-column label="备注" align="center" prop="billRemark" min-width="166" />
                </el-table>
              </div>
              <div style="width:30%">
                <div class="fontS14 fontBold marB5">未订货门店</div>
                <el-table style="width:100%" ref="multipleTable1" tooltip-effect="dark" :data="sourceShopList" border
                 :max-height="tableHeight2" :height="tableHeight2" v-loading="loadingSourceList" row-key="shopId">
                  <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
                  <el-table-column label="分店" align="center" prop="shopNoName" min-width="120" />
                  <el-table-column label="负责人" align="center" prop="linkEmployeeName" min-width="120" />
                  <el-table-column label="电话" align="center" prop="linkEmployeeTel" min-width="120" />
                </el-table>
              </div>
            </div>
            <bottomPage v-model="queryParams" :getList="getList" :isSelectAll="false" :totalCom="total" />
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" v-show="activeStep == 2 || activeStep == 3" :style="{height:tableHeight+'px'}">
        <template slot="cardContent">
          <div class="tableContent">
            <el-form ref="form" :model="planForm" :rules="rules">
              <el-table ref="dragTable" :data="planForm.detailItem" :max-height="tableHeight3" :height="tableHeight3" border
                v-loading="loadingTable" show-summary :summary-method="getSummaries" @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave">
                <el-table-column align="center" :label="tableCellLabel" width="80">
                  <template v-slot="scope">
                    <i v-show="scope.row.hoverRow" :disabled="disabled" @click="row('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"></i>
                    <i v-show="scope.row.hoverRow" :disabled="disabled" @click="row('del', scope.$index)"
                      class="el-icon-remove operateDel"></i>
                    <div v-show="!scope.row.hoverRow">
                      {{ scope.$index + 1 }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="*商品编码" width="160" align="center" prop="goodsNo">
                  <template slot-scope="scope">
                    <el-form-item :prop="'detailItem.' + scope.$index + '.goodsNo'"
                      :rules="rules[`detailItem.goodsNo`]">
                      <SelectRemote :disabled="disabled" v-model="scope.row.goodsNo" :option="optionGoods(scope.row)" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="商品名称" align="center" prop="goodsName" width="120" />

                <el-table-column label="规格" align="center" prop="goodsSpec" width="120" />

                <el-table-column label="*单位" align="center" prop="unitId" width="110">
                  <template slot-scope="scope">
                    <el-form-item :prop="'detailItem.' + scope.$index + '.unitId'" :rules="rules[`detailItem.unitId`]">
                      <el-select v-model="scope.row.unitId" :disabled="disabled" size="mini">
                        <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                          :value="item.unitId" />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="订单需求量" align="center" prop="needUnitQty" />

                <el-table-column label="*批数" align="center" width="120">
                  <template slot-scope="scope">
                    <el-form-item :prop="'detailItem.' + scope.$index + '.batchUnitQty'"
                      :rules="rules[`detailItem.batchUnitQty`]">
                      <el-input v-model="scope.row.batchUnitQty" :disabled="disabled"
                        @keyup.native="onkeyupBatchUnitQty(scope.row)" type="number" size="mini"
                        maxlength="7"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="*生产计划数" align="center" width="130">
                  <template slot-scope="scope">
                    <el-form-item :prop="'detailItem.' + scope.$index + '.unitQty'"
                      :rules="rules[`detailItem.unitQty`]">
                      <el-input type="number" @keyup.native="onkeyupUnitQty(scope.row)" v-model="scope.row.unitQty"
                        :disabled="disabled" size="mini" maxlength="7" @input="
                          limitInputlength(
                            scope.row.unitQty,
                            scope.row,
                            'unitQty',
                            true
                          )
                        "></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="半成品库存" align="center" prop="halfProductStoreQty" />
                <el-table-column label="成品库存" align="center" prop="productStoreQty" />
                <el-table-column label="备注" align="center" prop="remark" width="130">
                  <template slot-scope="scope">
                    <el-input size="mini" v-model="scope.row.remark" :disabled="disabled" maxlength="80"></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </template>
      </cardTitleCom>
    </div>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import bottomPage from "@/views/components/bottomPage"; //底部分页
import { getNewToday, getNewYesterday, getNewWeekStart } from "@/utils/newDate"; //时间
import { getTimes, getTimesIsPicker } from "@/utils/index.js";
import {
  planGetWizardAPI,
  planGetWizardDetailAPI,
  planGetGoodsInfo,
  planAddAPI,
} from "@/api/produce/bill/plan"; //生产计划单
import { produceListAPI } from "@/api/system/maintain/param/produceParam"; //业务参数的生产组控制
import { produceDeptList } from "@/api/produce/sets/produceDept"; //生产工厂
import { limitInputlength } from "@/utils/index.js"; //保留几位小数
import {
  getTableSelect, //下拉框
  getSelectAssign, //赋值
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
export default {
  name: "guideDetail",
  components: { cardTitleCom, bottomPage, SelectRemote },
  data() {
    return {
      total: 0, // 总条数
      listProduceGroup: this.$select({
        key: "listProduceGroup",
      }).option,
      listProduceOrder: this.$select({
        key: "listProduceOrder",
      }).option,
      tableCellLabel: "序号",
      isPlanGroup: "N",
      selectionWizard: { queryList: [] }, //第二步选中的源单
      newToday: [], //今天
      newYesterday: [], //昨天
      newWeekStart: [], //本周
      disabled: false, //禁用状态
      datetime: undefined, //单据查询时间
      datetimeArrival: undefined, //到货查询时间
      filterTime: undefined, //单据日期天数
      filterTimeArrival: undefined, //到货日期天数
      loading: false, //遮罩
      loadingSourceList: false, //源单遮罩
      loadingTable: false, //表格遮罩
      activeStep: 0, // 当前步骤的索引值
      // 表单参数
      queryParams: {
        isShopOrder: true, //门店订货单    是否
        isSell: false, //销售订单    是否
        isProduce: false, //生产计划单    是否
        isShopDue: false, //门店预订单    是否
        isProduceOtherOrder: false, //生产其他订单    是否
        isBillDate: false, //单据日期    是否
        isArrivalDate: false, //到货日期   是否
        isOrderingWarehouse: false, //订货仓库   是否
        isOrderingCustomer: false, //订货客户  是否
        isPickupShop: false, //取货门店  是否
        isProduceGroup: false, //生产组  是否
        isProduceOrder: false, //生产班次  是否
        pageNum: 1,
        pageSize: 100
      },
      sourceList: [], //源单信息
      sourceShopList: [],
      planForm: {
        detailItem: [{}],
      },
      // 表单校验
      rules: {
        "detailItem.goodsNo": [
          {
            required: true,
            message: "商品编码不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.batchUnitQty": [
          {
            required: true,
            message: "批产量数不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^\d{0,6}(\.\d{0,4})?$/,
            message: "超出输入字符范围",
          },
        ],
        "detailItem.unitQty": [
          {
            required: true,
            message: "生产计划数不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^\d{0,6}(\.\d{0,4})?$/,
            message: "超出输入字符范围",
          },
        ],
      },
       vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      currentH: 0, //搜索框高度
    };
  },
  watch: {
    //单据日期
    datetime(newVal) {
      if (newVal) {
        this.filterTime = getTimesIsPicker(newVal);
        this.queryParams.beginBillDate = newVal[0];
        this.queryParams.endBillDate = newVal[1];
      } else {
        this.queryParams.beginBillDate = undefined;
        this.queryParams.endBillDate = undefined;
        this.filterTime = undefined;
      }
    },
    //到货日期
    datetimeArrival(newVal) {
      if (newVal) {
        this.filterTimeArrival = getTimesIsPicker(newVal);
        this.queryParams.beginArrivalDate = newVal[0];
        this.queryParams.endArrivalDate = newVal[1];
      } else {
        this.queryParams.beginArrivalDate = undefined;
        this.queryParams.endArrivalDate = undefined;
        this.filterTimeArrival = undefined;
      }
    },
    //单据日期快捷
    filterTime: {
      handler(newVal) {
        if (newVal) {
          this.datetime = getTimes({
            type: newVal,
            format: "datetimerange",
          }).filter((ite) => typeof ite !== "undefined");
          this.queryParams.beginBillDate = this.datetime[0];
          this.queryParams.endBillDate = this.datetime[1];
        }
      },
      immediate: true,
    },
    //到货日期快捷
    filterTimeArrival: {
      handler(newVal) {
        if (newVal) {
          this.datetimeArrival = getTimes({
            type: newVal,
            format: "datetimerange",
          }).filter((ite) => typeof ite !== "undefined");
          this.queryParams.beginArrivalDate = this.datetimeArrival[0];
          this.queryParams.endArrivalDate = this.datetimeArrival[1];
        }
      },
      immediate: true,
    },
  },
  computed: {
    //商品下拉框配置项
    optionGoods() {
      var that = this;
      return function (row) {
        return getTableSelect(
          {
            goodsTypes: [1, 2],
            billDetailItems: this.planForm.detailItem,
            getMultipleGoods: this.getGoodS,
            row,
          },
          that
        );
      };
    },
    tableHeight() {
      return this.vivwH  - this.searchH - this.currentH -20;
    },
    tableHeight2() {
      return this.vivwH  - this.searchH - this.currentH -145;
    },
    tableHeight3() {
      return this.vivwH  - this.searchH - this.currentH -100;
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout();
      this.$refs.multipleTable1.doLayout();
      this.$refs.dragTable.doLayout();
    });
  },
  async created() {
    try {
      this.loading = true;
      this.filterTime = "today";
      this.filterTimeArrival = "today";
      this.reset();
      await this.getInitializeData();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
  mounted(){
      this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.searchH = document.getElementById("search-card")?.clientHeight;
      this.currentH = document.getElementById("current-progress")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
   beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
      handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
      this.currentH = document.getElementById("current-progress")?.clientHeight;
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (
        this.planForm.billStatus == "0" ||
        this.planForm.billStatus == "" ||
        !this.planForm.billStatus
      ) {
        this.$set(row, "hoverRow", true);
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(row) {
      // 移除hover的事件
      if (
        this.planForm.billStatus == "0" ||
        this.planForm.billStatus == "" ||
        !this.planForm.billStatus
      ) {
        this.$set(row, "hoverRow", false);
        this.tableCellLabel = "序号";
      }
    },
    limitInputlength,
    changeProduceGroupChange(v) {
      this.$set(this.queryParams, "produceGroupId", undefined);
      this.$set(this.queryParams, "produceOrderIds", undefined);
      this.listProduceGroup.remoteBody = { produceDeptId: v };
      this.listProduceOrder.remoteBody = { produceDeptId: v };
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    //退出
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      this.$destroy();
      this.$router.push("/produce/bill/plan");
    },
    /** 提交按钮 */
    async submitForm(isBool) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          await planAddAPI({
            ...this.planForm,
            produceDeptId: this.queryParams.produceDeptId,
            produceGroupId: this.queryParams.produceGroupId,
          });
          // data.deptName = data.produceDeptName
          //重新赋值给表单，可以把id赋值

          // this.planForm = data
          this.$modal.msgSuccess("新增单据成功");
          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              (item) => item.path === this.$route.path
            ),
            1
          );
          this.$destroy();
          this.$router.push("/produce/bill/plan");
        }
      });
    },
    //生产计划数
    onkeyupUnitQty(row) {
      if (row.batchProdQty && row.unitQty) {
        let batchUnitQty = row.unitQty / row.batchProdQty;
        batchUnitQty = this.formatNumber(batchUnitQty);
        this.$set(row, "batchUnitQty", batchUnitQty);
      }
    },
    //批数
    onkeyupBatchUnitQty(row) {
      if (row.batchProdQty && row.batchUnitQty) {
        let unitQty = row.batchProdQty * row.batchUnitQty;
        unitQty = this.formatNumber(unitQty);
        this.$set(row, "unitQty", unitQty);
      }
    },
    //选中的商品
    async getGoodS(rows) {
      //查询库存条件
      let goodsIds = rows.map((v) => v.goodsId);
      //查询库存
      let res = await planGetGoodsInfo({ goodsIds });
      let inventoryArr = res.data || [];
      rows = rows.map((item) => {
        item.remark = undefined;
        //序号
        item.hoverRow = false;
        //手动添加
        item.lineType = 0;
        const storeNum = inventoryArr.find((v) => v.goodsId == item.goodsId);
        //批产量
        item.batchProdQty = storeNum?.batchProdQty || 0;
        //半成品库存
        item.halfProductStoreQty = storeNum?.halfProductStoreQty || 0;
        //成品库存
        item.productStoreQty = storeNum?.productStoreQty || 0;
        return item;
      });
      this.planForm.detailItem = getSelectAssign(
        rows,
        this.planForm.detailItem
      );
    },
    // 源单信息 多选框选中数据
    handleSelectionChange(selection) {
      let queryList = selection.map((item) => {
        let obj = {
          importBillId: item.importBillId,
          importType: item.importType,
        };
        return obj;
      });
      this.selectionWizard = {
        produceGroupId: this.queryParams.produceGroupId,
        queryList,
      };
    },

    //初始化页面数据
    async getInitializeData() {
      //获取今天日期
      this.newToday = getNewToday();
      //获取昨天日期
      this.newYesterday = getNewYesterday();
      //获取本周日期
      this.newWeekStart = getNewWeekStart();

      //生产计划单分生产组制作
      const res5 = await produceListAPI();
      this.isPlanGroup = res5.data.system_BUSINESS_PRODUCE_CONFIG_12;
      this.queryParams.isProduceGroup = this.isPlanGroup == "Y" ? true : false;

      //默认生产工厂
      const res2 = await produceDeptList({ produceDeptNo: "0000" });
      if (res2.rows[0]?.produceDeptId) {
        //默认生产工厂
        this.$set(
          this.queryParams,
          "produceDeptId",
          res2.rows[0]?.produceDeptId
        );
        this.changeProduceGroupChange(res2.rows[0]?.produceDeptId);
      }
    },
    // 上一步骤
    lastStep() {
      if (this.activeStep > 0) {
        this.activeStep = this.activeStep - 1;
      }
    },
    // 下一步骤
    async nextStep() {
      if (!this.queryParams.produceDeptId)
        return this.$message.error("生产工厂不能为空");
      if (this.activeStep < 3) {
        if (
          this.activeStep == 1 &&
          this.selectionWizard.queryList.length == 0
        ) {
          return this.$message({
            message: "请选择单据",
            type: "warning",
          });
        }
        if (
          this.activeStep == 0 &&
          !this.queryParams.isShopOrder &&
          !this.queryParams.isSell &&
          !this.queryParams.isProduce &&
          !this.queryParams.isShopDue &&
          !this.queryParams.isProduceOtherOrder
        ) {
          return this.$message({
            message: "请选择单据类型",
            type: "warning",
          });
        }
        if (
          this.isPlanGroup == "Y" &&
          (!this.queryParams.isProduceGroup || !this.queryParams.produceGroupId)
        ) {
          return this.$message.error("请先选择生产组");
        }
        this.activeStep++;
        if (this.activeStep == 1) {
          await this.getList();
        } else if (this.activeStep == 2) {
          this.loadingTable = true;
          //获取第三步表格数据
          let res = await planGetWizardDetailAPI(this.selectionWizard);
          let data = res?.data || [];
          //查询库存条件
          let goodsIds = data.map((v) => v.goodsId);
          //查询库存
          let res2 = await planGetGoodsInfo({ goodsIds });
          let inventoryArr = res2.data || [];
          data = data.map((item) => {
            item.remark = undefined;
            //序号
            item.hoverRow = false;
            //手动添加
            item.lineType = 2;
            const storeNum = inventoryArr.find(
              (v) => v.goodsId == item.goodsId
            );
            //批产量
            item.batchProdQty = storeNum?.batchProdQty || 0;
            //半成品库存
            item.halfProductStoreQty = storeNum?.halfProductStoreQty || 0;
            //成品库存
            item.productStoreQty = storeNum?.productStoreQty || 0;
            //默认的生产计划数
            item.unitQty = item.needUnitQty;
            item.needUnitQty = item.needUnitQty || 0;
            item.batchProdQty = item.batchProdQty || 0;
            item.batchUnitQty =
              item.needUnitQty && item.batchProdQty
                ? (item.needUnitQty / item.batchProdQty).toFixed(1)
                : 0;
            return item;
          });
          this.planForm.detailItem = data;
          this.planForm.importDetailItem = this.selectionWizard.queryList;
          this.loadingTable = false;
        }
      }
    },
    reset() {
      this.sourceList = []; //源单信息
      this.sourceShopList = []
      this.newToday = []; //今天
      this.newYesterday = []; //昨天
      this.newWeekStart = []; //本周
      this.disabled = false; //按钮不禁
      this.selectionWizard = { queryList: [] }; //第二步选中的源单
      this.activeStep = 0; //第一步
      // 表单参数
      this.queryParams = {
        isShopOrder: true, //门店订货单    是否
        isSell: false, //销售订单    是否
        isProduce: false, //生产计划单    是否
        isShopDue: false, //门店预订单    是否
        isProduceOtherOrder: false, //生产其他订单    是否
        isBillDate: false, //单据日期    是否
        isArrivalDate: false, //到货日期   是否
        isOrderingWarehouse: false, //订货仓库   是否
        isOrderingCustomer: false, //订货客户  是否
        isPickupShop: false, //取货门店  是否
        isProduceGroup: false, //生产组  是否
        isProduceOrder: false, //生产班次  是否
        pageNum: 1,
        pageSize: 100
      };
      //单据表格
      this.planForm = {
        //单据表格-订单明细
        detailItem: [{}],
      };
      this.resetForm("form");
    },
    /** 第二步表格列表 */
    async getList() {
      this.loadingSourceList = true;
      //获取第二步表格数据
      const { rows, total, shops } = await planGetWizardAPI(this.queryParams);
      this.sourceList = rows || [];
      this.sourceShopList = shops || []
      this.$refs.multipleTable.toggleAllSelection();
      this.total = total || 0;
      this.loadingSourceList = false;
    },
    //表格当中增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.planForm.detailItem.splice(index + 1, 0, {
          goodsNo: "",
          hoverRow: false,
        });
      } else {
        if (this.planForm.detailItem.length <= 1) return;
        this.planForm.detailItem.splice(index, 1);
      }
    },
    //计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }

        if (index <= 4 || index == 10) {
          sums[index] = "";
          return;
        }
        let values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = `${Number(sums[index].toFixed(2))} `;
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
  },
};
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  //操作按钮
  .navBtnTop {
    padding: 6px 10px 6px 0;
  }
  //主体
  .content {
    padding: 0 10px 1px 10px;
    //进度条
    .theSteps {
      margin: 22px;
    }
    //内容
    .tableContent {
      padding: 10px 10px 10px 10px;
      .filterCriteria {
        height: 160px;
        background-color: #f2f2f2;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-left: 22px;
      }
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
      ::v-deep .el-form-item {
        padding: 0;
        margin: 0;
      }
    }
  }
}
//单据日期
::v-deep .el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 355px;
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
/* 复选框选中后的字体颜色 */
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #303133 !important;
}
/* 进行中状态：圈线 */
::v-deep .el-step__head.is-process {
  color: #338df7;
  border-color: #338df7;
}
/* 进行中状态：圈内 */
::v-deep .el-step__head.is-process > .el-step__icon {
  background: #338df7;
  color: #fff;
}
/* 进行中状态：title（文字） */
::v-deep .el-step__title.is-process {
  font-size: 12px;
  font-weight: normal;
}

/* 完成状态：圈线 */
::v-deep .el-step__head.is-success {
  color: #338df7;
  border-color: #338df7;
}
/* 完成状态：title（文字） */
::v-deep .el-step__title.is-success {
  color: #303133;
  font-size: 12px;
  font-weight: normal;
}
// 未完成:title(文字)
::v-deep .el-step__title.is-wait {
  color: #303133;
  font-size: 12px;
  font-weight: normal;
}
/* 完成状态：line
 * 描述：第一步完成，第二步进行时，之间的进度条有颜色
 */
::v-deep .el-step__head.is-success > .el-step__line > .el-step__line-inner {
  width: 100% !important;
  border-width: 1px !important;
}
::v-deep .el-steps .el-steps--horizontal {
  padding: 0 20px;
}

//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
