var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.matchMaterialForm,
        "label-width": "96px",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrap",
        },
        [
          _c("topOperatingButton", {
            attrs: {
              isImportOrderBtn: _vm.showImportBill,
              disabled: _vm.disabled,
              showAudit: _vm.showAudit,
              isAddBtn: _vm.showAdd,
            },
            on: {
              submitForm: _vm.submitForm,
              addBill: _vm.addBill,
              auditBill: _vm.auditBill,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
              getImportOrder: _vm.getImportOrder,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                billStatus: _vm.matchMaterialForm.billStatus,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-w marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: true,
                            placeholder: "单据编号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.matchMaterialForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.matchMaterialForm, "billNo", $$v)
                            },
                            expression: "matchMaterialForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            size: "mini",
                          },
                          model: {
                            value: _vm.matchMaterialForm.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.matchMaterialForm, "billDate", $$v)
                            },
                            expression: "matchMaterialForm.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手工单据号", prop: "handBillNo" } },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "手工单据号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.matchMaterialForm.handBillNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.matchMaterialForm, "handBillNo", $$v)
                            },
                            expression: "matchMaterialForm.handBillNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产工厂", prop: "produceDeptId" } },
                      [
                        _c("SelectRemote", {
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                              option: {
                                option: {
                                  disabled:
                                    _vm.disabled ||
                                    _vm.matchMaterialForm.billId != undefined,
                                },
                              },
                            }).option,
                          },
                          on: { selectChange: _vm.changeProduceGroupChange },
                          model: {
                            value: _vm.matchMaterialForm.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.matchMaterialForm,
                                "produceDeptId",
                                $$v
                              )
                            },
                            expression: "matchMaterialForm.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "配料仓库", prop: "matchStoreId" } },
                      [
                        _c("SelectRemote", {
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceStore",
                              option: {
                                option: {
                                  remoteBody: {
                                    produceDeptId:
                                      _vm.matchMaterialForm.produceDeptId,
                                  },
                                  disabled:
                                    _vm.disabled ||
                                    !_vm.matchMaterialForm.produceDeptId ||
                                    _vm.matchMaterialForm.billId != undefined,
                                  showItem: [
                                    {
                                      storeId:
                                        _vm.matchMaterialForm.matchStoreId,
                                      produceStoreName:
                                        _vm.matchMaterialForm.matchStoreName,
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.matchMaterialForm.matchStoreId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.matchMaterialForm,
                                "matchStoreId",
                                $$v
                              )
                            },
                            expression: "matchMaterialForm.matchStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "原料仓库", prop: "materialStoreId" } },
                      [
                        _c("SelectRemote", {
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceStore",
                              option: {
                                option: {
                                  remoteBody: {
                                    produceDeptId:
                                      _vm.matchMaterialForm.produceDeptId,
                                  },
                                  disabled:
                                    _vm.disabled ||
                                    !_vm.matchMaterialForm.produceDeptId ||
                                    _vm.matchMaterialForm.billId != undefined,
                                  showItem: [
                                    {
                                      storeId:
                                        _vm.matchMaterialForm.materialStoreId,
                                      produceStoreName:
                                        _vm.matchMaterialForm.materialStoreName,
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.matchMaterialForm.materialStoreId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.matchMaterialForm,
                                "materialStoreId",
                                $$v
                              )
                            },
                            expression: "matchMaterialForm.materialStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "半成品仓库", prop: "halfStoreId" } },
                      [
                        _c("SelectRemote", {
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceStore",
                              option: {
                                option: {
                                  remoteBody: {
                                    produceDeptId:
                                      _vm.matchMaterialForm.produceDeptId,
                                  },
                                  disabled:
                                    _vm.disabled ||
                                    !_vm.matchMaterialForm.produceDeptId ||
                                    _vm.matchMaterialForm.billId != undefined,
                                  showItem: [
                                    {
                                      storeId:
                                        _vm.matchMaterialForm.halfStoreId,
                                      produceStoreName:
                                        _vm.matchMaterialForm.halfStoreName,
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.matchMaterialForm.halfStoreId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.matchMaterialForm,
                                "halfStoreId",
                                $$v
                              )
                            },
                            expression: "matchMaterialForm.halfStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "配料类型", prop: "matchMaterialType" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: {
                              disabled:
                                _vm.matchMaterialForm.billId != undefined,
                            },
                            model: {
                              value: _vm.matchMaterialForm.matchMaterialType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.matchMaterialForm,
                                  "matchMaterialType",
                                  $$v
                                )
                              },
                              expression: "matchMaterialForm.matchMaterialType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("商品分开配料"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("商品汇总配料"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 5 },
                            size: "mini",
                          },
                          model: {
                            value: _vm.matchMaterialForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.matchMaterialForm, "billRemark", $$v)
                            },
                            expression: "matchMaterialForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "leftCardTitle" }, [
                _c(
                  "div",
                  { staticClass: "marL10 x-f" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "marR15",
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.queryParams.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "type", $$v)
                          },
                          expression: "queryParams.type",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 1 } }, [
                          _vm._v("全部商品"),
                        ]),
                        _c("el-radio-button", { attrs: { label: 2 } }, [
                          _vm._v("整单原料"),
                        ]),
                        _c("el-radio-button", { attrs: { label: 3 } }, [
                          _vm._v("整单半成品"),
                        ]),
                      ],
                      1
                    ),
                    _c("el-input", {
                      staticClass: "marR10",
                      staticStyle: { width: "150px" },
                      attrs: { size: "mini", placeholder: "请输入原料名称" },
                      model: {
                        value: _vm.queryParams.query,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "query", $$v)
                        },
                        expression: "queryParams.query",
                      },
                    }),
                    _c(
                      "el-button",
                      { attrs: { size: "mini", type: "primary" } },
                      [_vm._v("搜索")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "rightCardTitle" }, [
                _c(
                  "div",
                  { staticClass: "marR10 x-f" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          disabled: _vm.disabled,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEvent("refreshStock")
                          },
                        },
                      },
                      [_vm._v("刷新库存数")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "vxe-table",
                      {
                        ref: "multipleTable",
                        attrs: {
                          border: "",
                          "show-overflow": "",
                          "show-header-overflow": "",
                          data: _vm.virtualDetails,
                          loading: _vm.loadingTable,
                          "max-height": "560px",
                          height: "560px",
                          "checkbox-config": { trigger: "row" },
                          "row-config": { isHover: true, height: 60 },
                          "sort-config": { trigger: "cell" },
                          "scroll-y": { enabled: true },
                          "tree-config": {
                            transform: true,
                            rowField: "billDetailId",
                            parentField: "parentBillDetailId",
                          },
                          "column-config": { resizable: true },
                        },
                        on: {
                          "cell-mouseenter": _vm.cellMouseEnter,
                          "cell-mouseleave": _vm.cellMouseLeave,
                          "radio-change": _vm.radioChangeEvent,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _c("span", [_c("p", [_vm._v("暂无数据")])]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("vxe-column", {
                          attrs: {
                            align: "center",
                            title: _vm.tableCellLabel,
                            width: "80",
                            fixed: "left",
                            type: "seq",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.hoverRow &&
                                            !scope.row.usableBillDetailId,
                                          expression:
                                            "scope.row.hoverRow&&!scope.row.usableBillDetailId",
                                        },
                                      ],
                                      staticClass:
                                        "el-icon-circle-plus operatePush",
                                      style: {
                                        marginRight:
                                          scope.row.hoverRow &&
                                          scope.row.usableBillDetailId
                                            ? "15px"
                                            : 0,
                                      },
                                      attrs: { disabled: _vm.disabled },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.upRow(
                                            "push",
                                            scope.row,
                                            scope
                                          )
                                        },
                                      },
                                    }),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.hoverRow &&
                                            scope.row.usableBillDetailId,
                                          expression:
                                            "scope.row.hoverRow&&scope.row.usableBillDetailId ",
                                        },
                                      ],
                                      staticClass: "el-icon-remove operateDel",
                                      attrs: { disabled: _vm.disabled },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.upRow(
                                            "del",
                                            scope.row,
                                            scope
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !scope.row.hoverRow,
                                            expression: "!scope.row.hoverRow",
                                          },
                                        ],
                                      },
                                      [_vm._v(" " + _vm._s(scope.seq) + " ")]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.matchMaterialForm.matchMaterialType == 1
                          ? [
                              _c("vxe-column", {
                                attrs: {
                                  title: "商品编码",
                                  width: "180",
                                  align: "center",
                                  field: "goodsNo",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.planGoodsInfo
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.planGoodsInfo
                                                      .goodsNo
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2215465318
                                ),
                              }),
                              _c("vxe-column", {
                                attrs: {
                                  title: "商品名称",
                                  align: "center",
                                  field: "goodsName",
                                  width: "160",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.planGoodsInfo
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.planGoodsInfo
                                                      .goodsName
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1703620160
                                ),
                              }),
                              _c("vxe-column", {
                                attrs: {
                                  title: "单位",
                                  align: "center",
                                  field: "unitName",
                                  width: "110",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.planGoodsInfo
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.planGoodsInfo
                                                      .unitName
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2363824470
                                ),
                              }),
                              _c("vxe-column", {
                                attrs: {
                                  title: "商品数量",
                                  align: "center",
                                  field: "planUnitQty",
                                  width: "160",
                                },
                              }),
                              _c("vxe-column", {
                                attrs: {
                                  title: "商品条码",
                                  align: "center",
                                  field: "barcode",
                                  width: "160",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.planGoodsInfo
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.planGoodsInfo
                                                      .barcode
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  518519947
                                ),
                              }),
                            ]
                          : _vm._e(),
                        _c("vxe-column", {
                          attrs: {
                            title: "原料编码",
                            align: "center",
                            field: "goodsNo",
                            width: "160",
                            "tree-node": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.goodsInfo
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.goodsInfo.goodsNo)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "原料名称",
                            align: "center",
                            field: "goodsName",
                            width: "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.goodsInfo
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.goodsInfo.goodsName)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "规格",
                            align: "center",
                            field: "goodsSpec",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.goodsInfo
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.goodsInfo.goodsSpec)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "单位",
                            align: "center",
                            field: "unitName",
                            width: "110",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.goodsInfo
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.goodsInfo.unitName)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "启用被代替物料",
                            align: "center",
                            field: "isUseUsable",
                            width: "130",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "x-fc" },
                                    [
                                      !scope.row.usableBillDetailId
                                        ? _c("el-checkbox", {
                                            attrs: {
                                              disabled: _vm.isDisabled(
                                                scope.row
                                              ),
                                            },
                                            model: {
                                              value: scope.row.isUseUsable,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "isUseUsable",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.isUseUsable",
                                            },
                                          })
                                        : _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handOpenDia(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择替代商品")]
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "被代替原料编码",
                            align: "center",
                            field: "goodsNo",
                            width: "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.usableGoodsInfo
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.usableGoodsInfo.goodsNo
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "被代替原料名称",
                            align: "center",
                            field: "goodsName",
                            width: "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.usableGoodsInfo
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.usableGoodsInfo.goodsName
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "规格",
                            align: "center",
                            field: "goodsSpec",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.usableGoodsInfo
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.usableGoodsInfo.goodsSpec
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "单位",
                            align: "center",
                            field: "unitName",
                            width: "110",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.usableGoodsInfo
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.usableGoodsInfo.unitName
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "被代替数量",
                              align: "center",
                              field: "usableBasUnitQty",
                              width: "130",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.usableBillDetailId
                                      ? _c("el-input", {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            size: "mini",
                                            type: "number",
                                            maxlength: "7",
                                            sunyunFormat: function (v) {
                                              return _vm.$syInput(1, v)
                                            },
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.limitInputlength(
                                                scope.row.usableBasUnitQty,
                                                scope.row,
                                                "usableBasUnitQty",
                                                true
                                              )
                                            },
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              return _vm.onkeyupSubstitutedQuantity(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.usableBasUnitQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "usableBasUnitQty",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.usableBasUnitQty",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("span", [_vm._v("被代替数量")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "需求量",
                            align: "center",
                            field: "basUnitQty",
                            width: "120",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "配料间库存",
                            align: "center",
                            field: "matchStoreQty",
                            width: "120",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "原料库存",
                            align: "center",
                            field: "materialStoreQty",
                            width: "120",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "保留原料库存",
                            align: "center",
                            field: "materialStoreProtectQty",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      size: "mini",
                                      type: "number",
                                      maxlength: "7",
                                      sunyunFormat: function (v) {
                                        return _vm.$syInput(1, v)
                                      },
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.limitInputlength(
                                          scope.row.materialStoreProtectQty,
                                          scope.row,
                                          "materialStoreProtectQty",
                                          true
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        return _vm.handleEvent(
                                          "rawMaterial",
                                          scope.row
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.materialStoreProtectQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "materialStoreProtectQty",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scope.row.materialStoreProtectQty",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "半成品库存",
                            align: "center",
                            field: "halfStoreQty",
                            width: "120",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "保留半成品库存",
                            align: "center",
                            field: "halfStoreProtectQty",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      size: "mini",
                                      type: "number",
                                      maxlength: "7",
                                      sunyunFormat: function (v) {
                                        return _vm.$syInput(1, v)
                                      },
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.limitInputlength(
                                          scope.row.halfStoreProtectQty,
                                          scope.row,
                                          "halfStoreProtectQty",
                                          true
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        return _vm.handleEvent(
                                          "semiFinishedProduct",
                                          scope.row
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.halfStoreProtectQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "halfStoreProtectQty",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scope.row.halfStoreProtectQty",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "库存可用总数",
                            align: "center",
                            field: "canUseStoreQty",
                            width: "120",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "差异数",
                            align: "center",
                            field: "exoDiffBasUnitQty",
                            width: "120",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "实际差异数",
                            align: "center",
                            field: "factDiffBasUnitQty",
                            width: "120",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "领用基本单位数量",
                            align: "center",
                            field: "factLeadBasUnitQty",
                            width: "140",
                          },
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "领用数量",
                              align: "center",
                              field: "factLeadUnitQty",
                              width: "130",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.disabled,
                                        size: "mini",
                                        maxlength: "7",
                                        type: "number",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.limitInputlength(
                                            scope.row.factLeadUnitQty,
                                            scope.row,
                                            "unitQty",
                                            true
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.onkeyupUnitQty(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.factLeadUnitQty,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "factLeadUnitQty",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.factLeadUnitQty",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("领用数量")]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "领用单位",
                              align: "center",
                              field: "factLeadUnitId",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          size: "mini",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onkeyupUnitQty(scope.row)
                                          },
                                        },
                                        model: {
                                          value: scope.row.factLeadUnitId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "factLeadUnitId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.factLeadUnitId",
                                        },
                                      },
                                      _vm._l(
                                        scope.row.factLeadUnits,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.unitId,
                                            attrs: {
                                              label: item.unitName,
                                              value: item.unitId,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("领用单位")]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
      _c("c-dialog", {
        attrs: {
          title: _vm.title,
          width: 500,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  { ref: "ruleForm", attrs: { "label-width": "120px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "保留库存数量" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "number",
                            maxlength: "9",
                            autocomplete: "off",
                            placeholder: "请输入保留库存数量",
                          },
                          model: {
                            value: _vm.inventoryNum,
                            callback: function ($$v) {
                              _vm.inventoryNum = $$v
                            },
                            expression: "inventoryNum",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleEvent("submitInventory")
                      },
                    },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }