var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "navBtnTop x-c-end", attrs: { id: "search-card" } },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep !== 0,
                  expression: "activeStep !== 0",
                },
              ],
              attrs: { size: "mini" },
              on: { click: _vm.lastStep },
            },
            [_vm._v("上一步")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep !== 3,
                  expression: "activeStep !== 3",
                },
              ],
              attrs: { size: "mini" },
              on: { click: _vm.nextStep },
            },
            [_vm._v("下一步")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 3,
                  expression: "activeStep === 3",
                },
              ],
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.getQuit } },
            [_vm._v("退出")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "当前进度", id: "current-progress" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "theSteps" },
                  [
                    _c(
                      "el-steps",
                      {
                        attrs: {
                          "text-align:": "",
                          left: "",
                          active: _vm.activeStep,
                          "finish-status": "success",
                        },
                      },
                      [
                        _c("el-step", {
                          attrs: { title: "选择生成计划单条件" },
                        }),
                        _c("el-step", { attrs: { title: "引入源单" } }),
                        _c("el-step", { attrs: { title: "生成生产计划单" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep == 0,
                  expression: "activeStep == 0",
                },
              ],
              style: { height: _vm.tableHeight + "px" },
              attrs: { cardTitle: "基本信息" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "filterCriteria" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.queryParams.isShopOrder,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isShopOrder", $$v)
                            },
                            expression: "queryParams.isShopOrder",
                          },
                        },
                        [_vm._v("门店订货单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.queryParams.isSell,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isSell", $$v)
                            },
                            expression: "queryParams.isSell",
                          },
                        },
                        [_vm._v("销售订单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.queryParams.isProduce,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isProduce", $$v)
                            },
                            expression: "queryParams.isProduce",
                          },
                        },
                        [_vm._v("生产计划单")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.queryParams.isProduceOtherOrder,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "isProduceOtherOrder",
                                $$v
                              )
                            },
                            expression: "queryParams.isProduceOtherOrder",
                          },
                        },
                        [_vm._v("其他订货单")]
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "marT20" },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.queryParams.isBillDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isBillDate", $$v)
                            },
                            expression: "queryParams.isBillDate",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10 marL10" }, [
                          _vm._v("单据日期"),
                        ]),
                        _c("el-date-picker", {
                          staticClass: "marR10",
                          attrs: {
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.datetime,
                            callback: function ($$v) {
                              _vm.datetime = $$v
                            },
                            expression: "datetime",
                          },
                        }),
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.filterTime,
                              callback: function ($$v) {
                                _vm.filterTime = $$v
                              },
                              expression: "filterTime",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              { attrs: { label: "today" } },
                              [_vm._v("今天")]
                            ),
                            _c(
                              "el-radio-button",
                              { attrs: { label: "yesterday" } },
                              [_vm._v("昨天")]
                            ),
                            _c(
                              "el-radio-button",
                              { attrs: { label: "thisWeek" } },
                              [_vm._v("本周")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT20" },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.queryParams.isArrivalDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isArrivalDate", $$v)
                            },
                            expression: "queryParams.isArrivalDate",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10 marL10" }, [
                          _vm._v("到货日期"),
                        ]),
                        _c("el-date-picker", {
                          staticClass: "marR10",
                          attrs: {
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.datetimeArrival,
                            callback: function ($$v) {
                              _vm.datetimeArrival = $$v
                            },
                            expression: "datetimeArrival",
                          },
                        }),
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.filterTimeArrival,
                              callback: function ($$v) {
                                _vm.filterTimeArrival = $$v
                              },
                              expression: "filterTimeArrival",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              { attrs: { label: "today" } },
                              [_vm._v("今天")]
                            ),
                            _c(
                              "el-radio-button",
                              { attrs: { label: "yesterday" } },
                              [_vm._v("昨天")]
                            ),
                            _c(
                              "el-radio-button",
                              { attrs: { label: "thisWeek" } },
                              [_vm._v("本周")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT20" },
                      [
                        _c(
                          "i",
                          {
                            staticStyle: {
                              color: "#ff4949",
                              "margin-left": "23px",
                            },
                          },
                          [_vm._v("* ")]
                        ),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("生产工厂"),
                        ]),
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                            }).option,
                          },
                          on: { selectChange: _vm.changeProduceGroupChange },
                          model: {
                            value: _vm.queryParams.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "produceDeptId", $$v)
                            },
                            expression: "queryParams.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT20" },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.queryParams.isOrderingWarehouse,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "isOrderingWarehouse",
                                $$v
                              )
                            },
                            expression: "queryParams.isOrderingWarehouse",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10 marL10" }, [
                          _vm._v("订货仓库"),
                        ]),
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({ key: "listStore" }).option,
                          },
                          model: {
                            value: _vm.queryParams.orderingWarehouseIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "orderingWarehouseIds",
                                $$v
                              )
                            },
                            expression: "queryParams.orderingWarehouseIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT20" },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.queryParams.isOrderingCustomer,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "isOrderingCustomer",
                                $$v
                              )
                            },
                            expression: "queryParams.isOrderingCustomer",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10 marL10" }, [
                          _vm._v("订货客户"),
                        ]),
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({ key: "listCustomer" }).option,
                          },
                          model: {
                            value: _vm.queryParams.orderingCustomerIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "orderingCustomerIds",
                                $$v
                              )
                            },
                            expression: "queryParams.orderingCustomerIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marT20" },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.queryParams.isPickupShop,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isPickupShop", $$v)
                            },
                            expression: "queryParams.isPickupShop",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10 marL10" }, [
                          _vm._v("取货门店"),
                        ]),
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({ key: "listShop" }).option,
                          },
                          model: {
                            value: _vm.queryParams.pickupShopIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "pickupShopIds", $$v)
                            },
                            expression: "queryParams.pickupShopIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.isPlanGroup == "Y"
                      ? _c(
                          "div",
                          { staticClass: "marT20" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.queryParams.isProduceGroup,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryParams,
                                    "isProduceGroup",
                                    $$v
                                  )
                                },
                                expression: "queryParams.isProduceGroup",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "fontS14c3 marL20 marR15" },
                              [_vm._v("生产组")]
                            ),
                            _c("SelectRemote", {
                              staticStyle: { width: "250px" },
                              attrs: { option: _vm.listProduceGroup },
                              model: {
                                value: _vm.queryParams.produceGroupId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryParams,
                                    "produceGroupId",
                                    $$v
                                  )
                                },
                                expression: "queryParams.produceGroupId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "marT20 marB20" },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.queryParams.isProduceOrder,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isProduceOrder", $$v)
                            },
                            expression: "queryParams.isProduceOrder",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10 marL10" }, [
                          _vm._v("生产班次"),
                        ]),
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.listProduceOrder,
                          },
                          model: {
                            value: _vm.queryParams.produceOrderIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "produceOrderIds", $$v)
                            },
                            expression: "queryParams.produceOrderIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep == 1,
                  expression: "activeStep == 1",
                },
              ],
              style: { height: _vm.tableHeight + "px" },
              attrs: { cardTitle: "选择源单" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c("div", { staticClass: "x-bc" }, [
                      _c(
                        "div",
                        { staticStyle: { width: "68%" } },
                        [
                          _c("div", { staticClass: "fontS14 fontBold marB5" }, [
                            _vm._v("源单信息"),
                          ]),
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingSourceList,
                                  expression: "loadingSourceList",
                                },
                              ],
                              ref: "multipleTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.sourceList,
                                border: "",
                                "max-height": _vm.tableHeight2,
                                height: _vm.tableHeight2,
                                "reserve-selection": true,
                                "row-key": "billId",
                              },
                              on: {
                                "row-click": _vm.handleRowClick,
                                "selection-change": _vm.handleSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  width: "50",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  align: "center",
                                  prop: "userId",
                                  width: "60",
                                  type: "index",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单据编号",
                                  align: "center",
                                  prop: "billNo",
                                  "min-width": "170",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单据日期",
                                  align: "center",
                                  prop: "billDate",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生产工厂",
                                  align: "center",
                                  prop: "deptName",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生产组",
                                  align: "center",
                                  prop: "produceGroupName",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生产班次",
                                  align: "center",
                                  prop: "produceOrderName",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "金额",
                                  align: "center",
                                  prop: "billMoney",
                                  "min-width": "100",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "订货仓库",
                                  align: "center",
                                  prop: "inStoreName",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "取货仓库",
                                  align: "center",
                                  prop: "outStoreName",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "到货日期",
                                  align: "center",
                                  prop: "arrivalDate",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "客户编码",
                                  align: "center",
                                  prop: "customerNo",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "客户名称",
                                  align: "center",
                                  prop: "customerName",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "备注",
                                  align: "center",
                                  prop: "billRemark",
                                  "min-width": "166",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "30%" } },
                        [
                          _c("div", { staticClass: "fontS14 fontBold marB5" }, [
                            _vm._v("未订货门店"),
                          ]),
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingSourceList,
                                  expression: "loadingSourceList",
                                },
                              ],
                              ref: "multipleTable1",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.sourceShopList,
                                border: "",
                                "max-height": _vm.tableHeight2,
                                height: _vm.tableHeight2,
                                "row-key": "shopId",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  align: "center",
                                  prop: "userId",
                                  width: "80",
                                  type: "index",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "分店",
                                  align: "center",
                                  prop: "shopNoName",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "负责人",
                                  align: "center",
                                  prop: "linkEmployeeName",
                                  "min-width": "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "电话",
                                  align: "center",
                                  prop: "linkEmployeeTel",
                                  "min-width": "120",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("bottomPage", {
                      attrs: {
                        getList: _vm.getList,
                        isSelectAll: false,
                        totalCom: _vm.total,
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep == 2 || _vm.activeStep == 3,
                  expression: "activeStep == 2 || activeStep == 3",
                },
              ],
              style: { height: _vm.tableHeight + "px" },
              attrs: { cardTitle: "商品信息" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.planForm, rules: _vm.rules },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "dragTable",
                            attrs: {
                              data: _vm.planForm.detailItem,
                              "max-height": _vm.tableHeight3,
                              height: _vm.tableHeight3,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                            on: {
                              "cell-mouse-enter": _vm.cellMouseEnter,
                              "cell-mouse-leave": _vm.cellMouseLeave,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: _vm.tableCellLabel,
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row("push", scope.$index)
                                          },
                                        },
                                      }),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row("del", scope.$index)
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !scope.row.hoverRow,
                                              expression: "!scope.row.hoverRow",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.$index + 1) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "*商品编码",
                                width: "160",
                                align: "center",
                                prop: "goodsNo",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "detailItem." +
                                              scope.$index +
                                              ".goodsNo",
                                            rules:
                                              _vm.rules["detailItem.goodsNo"],
                                          },
                                        },
                                        [
                                          _c("SelectRemote", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              option: _vm.optionGoods(
                                                scope.row
                                              ),
                                            },
                                            model: {
                                              value: scope.row.goodsNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "goodsNo",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.goodsNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "*单位",
                                align: "center",
                                prop: "unitId",
                                width: "110",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "detailItem." +
                                              scope.$index +
                                              ".unitId",
                                            rules:
                                              _vm.rules["detailItem.unitId"],
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                size: "mini",
                                              },
                                              model: {
                                                value: scope.row.unitId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "unitId",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.unitId",
                                              },
                                            },
                                            _vm._l(
                                              scope.row.units,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.unitId,
                                                  attrs: {
                                                    label: item.unitName,
                                                    value: item.unitId,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "订单需求量",
                                align: "center",
                                prop: "needUnitQty",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "*批数",
                                align: "center",
                                width: "120",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "detailItem." +
                                              scope.$index +
                                              ".batchUnitQty",
                                            rules:
                                              _vm.rules[
                                                "detailItem.batchUnitQty"
                                              ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "number",
                                              size: "mini",
                                              maxlength: "7",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                return _vm.onkeyupBatchUnitQty(
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.batchUnitQty,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "batchUnitQty",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.batchUnitQty",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "*生产计划数",
                                align: "center",
                                width: "130",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "detailItem." +
                                              scope.$index +
                                              ".unitQty",
                                            rules:
                                              _vm.rules["detailItem.unitQty"],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "number",
                                              disabled: _vm.disabled,
                                              size: "mini",
                                              maxlength: "7",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  scope.row.unitQty,
                                                  scope.row,
                                                  "unitQty",
                                                  true
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                return _vm.onkeyupUnitQty(
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.unitQty,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitQty",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitQty",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "半成品库存",
                                align: "center",
                                prop: "halfProductStoreQty",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "成品库存",
                                align: "center",
                                prop: "productStoreQty",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "remark",
                                width: "130",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          disabled: _vm.disabled,
                                          maxlength: "80",
                                        },
                                        model: {
                                          value: scope.row.remark,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "remark", $$v)
                                          },
                                          expression: "scope.row.remark",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }